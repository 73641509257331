@font-face {
  font-family: Helvetica Neue;
  src: url("HelveticaNeue-Thin.otf");
  font-weight: 100;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("HelveticaNeue-Ultralight.otf");
  font-weight: 200;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("HelveticaNeue-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("HelveticaNeue-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("HelveticaNeue-Regular.otf");
  font-weight: 500;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("HelveticaNeue-Medium.otf");
  font-weight: 600;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("HelveticaNeue-Medium.otf");
  font-weight: 700;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("HelveticaNeue-Bold.otf");
  font-weight: 800;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("HelveticaNeue-Bold.otf");
  font-weight: 900;
}
