@font-face {
  font-family: Inter;
  src: url("InterDisplay-Thin.otf");
  font-weight: 100;
}

@font-face {
  font-family: Inter;
  src: url("InterDisplay-ExtraLight.otf");
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url("InterDisplay-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url("InterDisplay-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url("InterDisplay-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url("InterDisplay-Medium.otf");
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url("InterDisplay-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url("InterDisplay-ExtraBold.otf");
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url("InterDisplay-Black.otf");
  font-weight: 900;
}
