@font-face {
  font-family: San Francisco;
  src: url("sf-pro-display_thin.woff2");
  font-weight: 100;
}

@font-face {
  font-family: San Francisco;
  src: url("sf-pro-display_ultralight.woff2");
  font-weight: 200;
}

@font-face {
  font-family: San Francisco;
  src: url("sf-pro-display_light.woff2");
  font-weight: 300;
}

@font-face {
  font-family: San Francisco;
  src: url("sf-pro-display_regular.woff2");
  font-weight: 400;
}

@font-face {
  font-family: San Francisco;
  src: url("sf-pro-display_medium.woff2");
  font-weight: 500;
}

@font-face {
  font-family: San Francisco;
  src: url("sf-pro-display_semibold.woff2");
  font-weight: 600;
}

@font-face {
  font-family: San Francisco;
  src: url("sf-pro-display_semibold.woff2");
  font-weight: 700;
}