@import "../res/fonts/SF-Pro-Display/index.css";
@import "../res/fonts/HelveticaNeue/index.css";
@import "../res/fonts/Inter/index.css";
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans
  min-h-screen
  antialiased
  text-dark-100
  bg-dark-1000
}

@layer base {
  h1 {
    @apply font-light
    text-5xl
    text-white
    font-sans;
  }

  h2 {
    @apply text-2xl
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold
    text-white
    font-sans
    m-0;
  }

  p {
    @apply
    text-gray-300
    font-sans
    leading-6
  }

  b {
    @apply
    font-bold
    leading-7
  }

  label {
    @apply text-sm
    text-dark-400
    font-sans;
  }

  blockquote {
    @apply border-l-4 border-0 border-solid border-dark-500
    m-0 pl-4;
  }
}

blockquote {
  margin: 0 !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently                                  supported by Chrome, Edge, Opera and Firefox */
}

@layer base {
  button {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }

  input {
    outline: none;
    border: none;
  }
}

.Toastify__toast {
  opacity: 0.9 !important;
  background: linear-gradient(to right, #5d985a, #71c09b) !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  height: 4px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #13151d !important;
}

.sv-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  height: 0px !important;
}

.audio-visualiser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 400px;

  > div {
    z-index: 1;
  }

  h2 {
    margin: 4px;
  }

  h3 {
    margin: 0 0 16px;
  }

  canvas {
    position: absolute;
    width: 400px;
    height: 400px;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

@layer components {
  .container {
    @apply w-full max-w-7xl
    p-10 md:p-12 xl:p-20;
  }

  .select {
    @apply flex items-center
    w-60
    text-dark-100 text-lg font-bold text-left
    py-2 px-4
    rounded-lg
    transition
    bg-dark-1000
    border border-dark-900
    hover:bg-dark-900
    disabled:bg-dark-900 disabled:hover:bg-dark-900;
  }

  .select-options {
    @apply grid grid-cols-1 items-center divide-y divide-dark-700
    w-60
    text-dark-100 text-lg font-bold text-left
    rounded-lg
    transition
    bg-dark-1000;
  }

  .select-option {
    @apply px-4 py-2
    rounded
    hover:bg-primary-900
    cursor-pointer;
  }

  .radio-group-option {
    @apply flex items-center gap-2
    text-lg
    p-3 my-2
    rounded-md
    border border-solid border-gray-800 border-opacity-0
    bg-gray-400 bg-opacity-10
    transition duration-100
    pointer-events-none;
  }

  .radio-group-option.active {
    @apply border-primary-500
    bg-primary-500 bg-opacity-10
    text-primary-400 children:text-primary-400;
  }

  .modal {
    @apply animate-modal
    bg-dark-900
    p-5 md:p-12
    w-full max-w-3xl;
  }

  .modal.sm {
    @apply p-5 md:p-10
    max-w-xl;
  }

  .modal.lg {
    @apply max-w-5xl;
  }

  .modal.xl {
    @apply max-w-7xl;
  }

  .modal.xxl {
    @apply max-w-[1280px];
    foo: bar;
  }

  .modal.full {
    @apply md:p-20 lg:p-28
    md:m-20 lg:m-28
    max-w-full;
  }
}

.swiper-pagination-progressbar-fill {
  background: #4a36d0 !important;
}

.sidebar {
  @apply
  hidden lg:block
  w-80 h-[calc(100vh-66px)] p-4
  backdrop-blur-xl
}

.player.transition {
  position: fixed;
  display: flex !important;
  align-items: center;
  z-index: 999;
  background: rgba(11, 10, 12, 0.8);
  width: calc(100% - 32px);
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.3);
  margin: 16px;
  padding: 16px;
  bottom: 0;
  border-radius: 6px;
  border: 1px solid #1c1c20;
  backdrop-filter: blur(6px);

  .details {
    width: 180px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .image {
      height: 64px;
      margin-right: 12px;
    }

    p {
      margin: 0;
    }

    span {
      color: #9a9a9a;
    }
  }

  .seeker {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: auto;
    padding: 0 16px;

    .controls {
      display: flex;
      align-items: center;
      margin: 4px auto;
    }

    .seek-container {
      display: flex;
      align-items: center;

      p {
        font-size: 10px;
        margin: 0 16px;
      }
    }
  }

  .vol {
    display: flex;
    align-items: center;
    width: 180px;
    padding: 0 32px;

    .icon {
      color: #ababab;
      margin: 8px 16px 14px;
    }
  }

  .closebutton {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}


/**
Forms
 */

.sq-form-stage {
  @apply py-8;
}

.sq-steps {
  @apply text-xl text-dark-500 span:text-dark-300
  tracking-wider
  m-0 mb-2;
}


/**
  Filepond Customization
 */

/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
  cursor: pointer;
}

/* the text color of the drop label*/
.filepond--drop-label, .filepond--root, .filepond--panel-root, .filepond--panel-root.filepond--panel-bottom {
  background: #040711 !important;
  @apply
  bg-dark-1000
}

.filepond--wrapper {
  margin-bottom: -14px;
}

.filepond--file {
  @apply bg-dark-600
}

/* underline color for "Browse" button */
span.filepond--label-action {
  @apply decoration-dark-500
}

/* the background color of the drop circle */
.filepond--drip-blob {
  @apply bg-primary-500
}

/* the background color of the black action buttons */
.filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.5);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
  color: white;
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(0, 0, 0, 0.9);
}

/* the text color of the file status and info labels */
.filepond--item-panel {
  color: white;
  border: none !important;
  background: rgb(54, 57, 61) !important;
}

/* error state color */
[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
  background-color: red;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: green;
}

.filepond--credits {
  display: none;
}

/* Prevent tab in swiper form */
.swiper-form > * > .swiper-slide {
  visibility: hidden;
}

.swiper-form > * > .swiper-slide.swiper-slide-active {
  visibility: visible;
}

.ql-editor {
  p {
    margin-bottom: 0;
  }
  ul {
    margin: 8px 0;
    padding-left: 16px;
  }
}

.ql-mention-list-container {
  z-index: 300;
}